import React, { useState } from "react";
import { Layout, Menu, theme, Typography, Button, Flex, message } from "antd";
import TextArea from "components/TextArea";
import { useMutation } from "react-query";

import axios from "axios";

const { Content } = Layout;

const items1 = [
  {
    key: 1,
    label: `Standard`,
  },
  {
    key: 2,
    label: `Professional`,
    disabled: true,
  },
  {
    key: 3,
    label: `Fluency`,
    disabled: true,
  },
  {
    key: 4,
    label: `Slang`,
    disabled: true,
  },
];

const App = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
const [messageApi, contextHolder] = message.useMessage();
  const [input, setInput] = useState();

  const mutation = useMutation({
    mutationFn: (body) => {
      return axios.post("/generate", body);
    },
  });


  return (
    <Content
      style={{
        padding: "30px",
      }}
    >
      {contextHolder}
      <Layout
        style={{
          padding: "24px 0",
          background: colorBgContainer,
        }}
      >
        <Content
          style={{
            padding: "0 24px",
            minHeight: 280,
          }}
        >
          <Typography.Title level={1} style={{ margin: 5, marginBottom: 30 }}>
            Paraphrasing Tool
          </Typography.Title>
          <Menu
            selectedKeys={["1"]}
            mode="horizontal"
            defaultSelectedKeys={["2"]}
            items={items1}
            style={{ marginBottom: 10 }}
          />
          <Flex justify="space-evenly" gap="middle" wrap="wrap">
            <Flex vertical={true} flex={1}>
              <Flex>
                <TextArea numberOfRows={15} showCount={true} value={input} onChange={(e)=>setInput(e.target.value)}/>
              </Flex>

              <Flex style={{ marginTop: 30 }} justify="flex-end">
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  loading={mutation?.isLoading}
                  onClick={() => {
                    if(!input?.length || !input?.length > 500) {
                      messageApi.error('Invalid input.');
                    }
                    mutation.mutate({ input })
                  }}
                >
                  Submit
                </Button>
              </Flex>
            </Flex>
            <Flex flex={1} className="position-relative">
              {mutation?.data?.data?.output && <div className="overlay" onClick={()=>{
                navigator.clipboard.writeText(mutation?.data?.data?.output);
                messageApi.success('Copied to clipboard', 0.5);
              }}><p>Copy to clipboard</p></div>}
              <TextArea disabled value={mutation?.data?.data?.output} placeholder="" onCopyToClipboard={mutation?.data?.data?.output ? () => {
                navigator.clipboard.writeText(mutation?.data?.data?.output);
                messageApi.success('Copied to clipboard', 0.5);
              }: null}/>
            </Flex>
          </Flex>
        </Content>
      </Layout>
    </Content>
  );
};
export default App;

import React, { useState } from "react";
import { Input, Button } from "antd";

import {CopyFilled} from '@ant-design/icons'

const { TextArea } = Input;

const App = ({
  numberOfRows = 15,
  showCount = false,
  disabled = false,
  value,
  onChange,
  onClick=()=>{},
  onCopyToClipboard,
  placeholder="Enter the text"
}) => {
  return (
    <>
    <TextArea
      disabled={disabled}
      style={{ marginVertical: 40 }}
      value={value}
      count={{
        show: showCount,
        max: 500,
        strategy: (txt) => txt.length,
        // exceedFormatter: (txt, { max }) => {
        //   console.log("TEXT", typeof txt);
        //   return txt.substr(0, max).join("");
        // },
      }}
      onChange={onChange}
      onClick={onClick}
      placeholder={placeholder}
      autoSize={{ minRows: numberOfRows, maxRows: numberOfRows }}
    />
    {!!onCopyToClipboard&& <Button
    className="textarea-copy"
    style={{position: 'absolute', bottom: 85, right : 10}}
          type="text"
          icon={<CopyFilled />}
          onClick={onCopyToClipboard}
        >
          Copy
        </Button>}
    
    </>
  );
};

export default App;

import React from "react";
import Home from "screens/main/Home";

import { QueryClient, QueryClientProvider } from "react-query";

import { Layout, Flex } from "antd";
import axios from "axios";

const { Header, Footer } = Layout;

const queryClient = new QueryClient();

// axios.defaults.baseURL = "http://127.0.0.1:5001/parafrasi/us-central1/api";
axios.defaults.baseURL = "https://us-central1-parafrasi.cloudfunctions.net/api";

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Layout>
        <Header
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Flex vertical justify="center">
            <Flex>
              <img src="/images/parafrasi.png" height={100}></img>
            </Flex>
          </Flex>
        </Header>
        <Home />
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          Powered by Weva Info Solution (OPC) Pvt. Ltd. ©2023
        </Footer>
      </Layout>
    </QueryClientProvider>
  );
};
export default App;
